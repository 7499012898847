import {AxiosInstance} from "axios";
import apiHelper from "./apiHelper";
import {AppConfig} from "../AppConfig";

const AUDIENCE_ORIGIN_BASE_URL = `${AppConfig.aoPortalApiUrl}`;

enum EndPoints {
  MetadataStatistics = "/getData/metadata_statistics",
  MetadataFileHistory = "/getData/metadata_file_history",
  NetMetadataDiff = "/workflow/compare/weightExcelFile",
  NetMetadataNewDiff = "/workflow/compare/netYamlFiles",
  NetyamldataDiff =  "/workflow/compare/weight/yamlFile",
  MetadataDiff = "/workflow/compare/metadataFiles",
  YamlMetadataDiff = "/yaml/compare/metadata_files/",
  TriggerDag = "/workflow/triggerMetadataIngestion",
  RawDataLoadFiles = "/getData/raw_data_load_statistics",
  RawDataLoadRejectionFiles = "/getData/raw_data_load_rejections",
  RawDataTriggerDAG = "/workflow/trigger",
  SurveySummary = "/getData/survey_statistics",
  ExportStatus = "/getData/export_statistics",
  AscriptionDataLoadFiles = "/getData/ascription_data_load_statistics",
  AscriptionDataLoadRejectionFiles = "/getData/ascription_data_load_rejections",
  SurveyWaves = "/getData/ao_survey",
  DownloadFiles = "/netConfig/yamlDownload",
  DownloadedFiles = "/weightConfig/download",

  //TODO: Update this with actual net variable load path once it's ready
  NetDataLoadFiles = "/getData/raw_data_load_statistics",
  NetDataLoadRejectionFiles = "/getData/raw_data_load_rejections",
  NetMetadataStatistics = "/getData/net_metadata_statistics",
  WeightMetadataStatistics = "/getData/weight_metadata_statistics",
  WeightMetadataFilesInfo = "/getData/weight_metadata_files_info",
  VariableOrderingStatistics = "/getData/variable_ordering_statistics",
  NetDataCalculations = "/getData/net_calculated_statistics",
  NetMetadataFilesInfo = "/getData/net_metadata_files_info",
  WeightSnowflakeToSnowflakeStatistics = "/getData/weight_snowflake_to_snowflake_statistics",
  WeightSnowflakeToSnowflakeTable = "/getData/weight_snowflake_to_snowflake_table",
  ExportGlobalStatistics = "/getData/export_global_statistics",
  ExportMarketsStatistics = "/getData/export_markets_statistics",
  TriggerDAG = "/workflow/trigger",
}

const request:AxiosInstance = apiHelper(AUDIENCE_ORIGIN_BASE_URL)

export { request as AudienceOriginRequest, EndPoints };
