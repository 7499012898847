import {AppConfig, getIngestionGCSPrefix} from "../../../AppConfig";

export const STEP_CONTENT = {
    INPUT_FILE: {
        DOWNLOAD_SPEC_FILE: {
            description: "Please download the dictionary and specification file from the below link.",
            checkboxText: "Files downloaded successfully",
        },
    },
    RENAME_DATA_DICTIONARY: {
        SPEC_FILE: {
            description:
                "Rename the data dictionary as AO[YY][WD]DIC_XXXX.xlsx (e.g. AO22W1DC_12345.xlsx)",
            checkboxText: "Data file changed according to the format above",
        },
        YEAR: {
            description: "YY is year, e.g., 21, 22, 23",
            checkboxText: "YY as displayed",
        },
        WD: {
            description: "WD is W1,W2,W3,W4 ",
            checkboxText: "Wave name changed as required",
        },
        VV: {
            description:
                "both tabs are used (Variables and Values) please also check the headers to match the example file",
            checkboxText: "Tabs named and formatted according to the requirement",
        },
    },
    RENAME_SPEC_FILE: {
        SPEC_FILE: {
            description:
                "Now let's rename the spec as AO[YY][WD]SPEC_XXXX.xlsx (e.g. AO22W1DC_12345.xlsx)",
            checkboxText: "spec file name changed according to the format",
        },
        YEAR: {
            description: "YY is year, e.g., 21, 22, 23",
            checkboxText: "Year code has been formatted as 'YY'",
        },
        WD: {
            description: "WD is W1,W2,W3,W4 ",
            checkboxText: "Next Wave code has been formatted accordingly",
        },
        VNF: {
            description:
                "only SPSS spec tab is used , only two columns from it (Variable New and Filter)",
            checkboxText: "spec file checkbox",
        },
        NEW_TAB: {
            description:
                "add a new tab called Markets, put one column (Market Code) in it, fill out the markets in this wave  using the code info",
            checkboxText: "spec file checkbox",
        },
    },
    UPLOAD_FILE: {
        UPLOAD_SPEC_FILE: {
            description: "Please upload files here",
            checkboxText: "Files uploaded",
        },
    },
    TriggerMetadataIngestionDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_metadata_v2",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_meta_dag_v2/graph`,
        inputFields: [
            // {
            //     fieldId: "year",
            //     fieldLabel: "Year: ",
            //     fieldType: "dropdown",
            //     defaultValue: "",
            //     tooltip: "2 digit year for survey, e.g. 22, 23, 24"
            // },
            // {
            //     fieldId: "wave_label",
            //     fieldLabel: "Wave Label: ",
            //     fieldType: "dropdown",
            //     defaultValue: "",
            //     tooltip: "Wave number W1,W2,W3,W4, OFFLINEW2 etc.."
            // },
        ]
    },
    metadataIngestionWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22337563033672/AO+raw+metadata+pipeline",
    metadataUploadUrl:
        getIngestionGCSPrefix(AppConfig.environment, "metadata"),
};

