import { AgGridReact } from "ag-grid-react";
import React, { useRef, useState } from "react";
import { PaginationChangeEventDetail } from "@platform-ui-kit/components-library";
import HeaderCell from "./HeaderCell";
import {
  WppButton,
  WppPagination,
  WppSideModal,
  WppSpinner,
  WppTooltip,
  WppTypography,
} from "@platform-ui-kit/components-library-react";

export const IngestionGrid = (props: {
  data: any[];
  columns: any[];
  status: string;
  onRowClicked?: (event: any) => void;
  onCellClicked?: (event: any) => void;
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [paginationPageSize, setPaginationPageSize] = useState<number>(5);
  const [currPage, setPage] = useState<number>(1);

  const [isModalOpen, setModalStatus] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    body: "",
  });

  const handleOpenModal = () => setModalStatus(true);
  const handleCloseModal = () => setModalStatus(false);

  const onCellClicked = (event: any) => {
    if (
      event.api.getColumnDefs()[event.event.target.ariaColIndex - 1] !=
      undefined
    ) {
      setModalData({
        title:
          event.api.getColumnDefs()[event.event.target.ariaColIndex - 1]
            .headerName,
        body: event.event.target.textContent,
      });
      console.log(event.event.target.textContent);
      handleOpenModal();
    }
  };

    const getModalBody = (value: string) => {
        if (!value) {
            return "-";
        }
  
        const transformVal = value.split(",");
        if (transformVal.length === 1) {
            return transformVal[0];
        }

    return (
      <ul>
        {transformVal.map((val, i) => (
          <li key={i}>{val}</li>
        ))}
      </ul>
    );
  };

  const handlePaginationChange = (
    event: CustomEvent<PaginationChangeEventDetail>
  ) => {
    const { itemsPerPage, page } = event.detail;

    gridRef.current!.api.showLoadingOverlay();
    if (paginationPageSize !== itemsPerPage) {
      gridRef.current!.api.paginationSetPageSize(itemsPerPage);
      setPaginationPageSize(itemsPerPage);
    }

    if (currPage !== page) {
      gridRef.current!.api.paginationGoToPage(page - 1);
      setPage(page);
    }

    gridRef.current!.api.hideOverlay();
  };

  function onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
  }

  return (
    <div style={{ width: "100%", marginBottom: 20 }} className="ag-theme-wpp">
      <AgGridReact
        rowData={props.status === "loading" ? null : props.data ?? []}
        ref={gridRef}
        columnDefs={props.columns}
        headerHeight={40}
        rowHeight={60}
        pagination
        paginationPageSize={paginationPageSize}
        suppressPaginationPanel
        loadingOverlayComponent={() => <WppSpinner size="m" />}
        domLayout={"autoHeight"}
        onGridReady={onGridReady}
        tooltipShowDelay={0}
        tooltipHideDelay={1500}
        defaultColDef={{
          flex: 1,
          minWidth: 100,
          
          headerComponent: HeaderCell,
          
          sortable: false,
        }}
        onRowClicked={props.onRowClicked}
        onCellClicked={onCellClicked}
      />
      <WppPagination
        count={props.data?.length ?? 0}
        itemsPerPage={[5, 10]}
        activePageNumber={currPage}
        pageSelectThreshold={9}
        data-testid="pagination-list"
        selectedItemPerPage={paginationPageSize}
        onWppChange={handlePaginationChange}
      />

      <WppSideModal
        open={isModalOpen}
        onWppSideModalClose={handleCloseModal}
        onWppSideModalOpen={handleOpenModal}
        size="l"
      >
        <h5 style={{ margin: 0 }} slot="header">
          {modalData.title}
        </h5>
        <div slot="body">{getModalBody(modalData.body)}</div>
        <div slot="actions">
          <WppButton variant="primary" size="m" onClick={handleCloseModal}>
            Close
          </WppButton>
        </div>
      </WppSideModal>
    </div>
  );
};
