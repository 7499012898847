import {AppConfig, getIngestionGCSPrefix} from "../../../AppConfig";

export const WeightDagConstants = {
    weightConfigDataUploadUrl:
       getIngestionGCSPrefix(AppConfig.environment, "weight_config"),
    weightCalcGlobalFileUploadPath:
        getIngestionGCSPrefix(AppConfig.environment, "weight_global_file"),
    TriggerWeightMetadataIngestionDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_weights_metadata",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_weights_metadata/graph`,
        inputFields: [
            {
                fieldId: "snowflake_schema",
                fieldLabel: "Schema: ",
                fieldType: "string",
                tooltip: "Schema name in snowflake",
                defaultValue: "AO_SCHEMA"
            },
            //  {
            //     fieldId: "markets",
            //     fieldLabel: "Markets: ",
            //     fieldType: "dropdown",
            //     tooltip: "CSV of market codes (use \"*\" to include all markets)"
            // }
            {
                fieldId: "year",
                fieldLabel: "Year: ",
                fieldType: "dropdown",
               tooltip: "4 digit year for survey, e.g. 2022, 2023, 2024"
            },

            {
                fieldId: "wave_label",
                fieldLabel: "Wave Label: ",
                fieldType: "dropdown",
                tooltip: "Wave number W1,W2,W3,OFFLINEW4 etc.."
            },
            {
                fieldId: "triggered_by",
                fieldLabel: "Operator: ",
                fieldType: "string",
                defaultValue: "Airflow",
                tooltip: "Your name for logging purposes"
            },
            {
                fieldId: "gcs_config_input_location",
                fieldLabel: "GCS Location: ",
                fieldType: "string",
                defaultValue:  getIngestionGCSPrefix(AppConfig.environment, "weight_config"),
                tooltip: "GCS location where weight config files are uploaded"
            }
        ]
    },
    TriggerWeightCalMarketFilesDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_weights_etl_market_files",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_weights_etl_market_files/graph`,
        inputFields: [
            {
                fieldId: "gcs_bucket_name",
                fieldLabel: "GCS Bucket: ",
                fieldType: "string",
                defaultValue: getIngestionGCSPrefix(AppConfig.environment, "gcs_bucket"),
                tooltip: "Give the GCS bucket name where the spss files are stored"
            },
            {
                fieldId: "gcs_market_files_path",
                fieldLabel: "GCS Input Path: ",
                fieldType: "string",
                defaultValue: "pipelines/audience_origin_weights_market_files/market_files",
                tooltip: "Give the GCS bucket prefix name where the spss files are stored"
            },
            {
                fieldId: "gcs_weights_file_merged_upload_path",
                fieldLabel: "GCS Output Path",
                fieldType: "string",
                defaultValue: "pipelines/audience_origin_weights_market_files/market_weights_merged_export",
                tooltip: "Give gcs prefix where the final generated merged result file will be stored"
            },
            {
                fieldId: "year",
                fieldLabel: "Year: ",
                fieldType: "dropdown",
                tooltip: "2 digit year for survey, e.g. 22, 23, 24"
            },

            {
                fieldId: "wave",
                fieldLabel: "Wave Label: ",
                fieldType: "dropdown",
                tooltip: "Wave number W1,W2,W3,OFFLINEW4 etc.."
            },
            {
                fieldId: "weight_calculation_types",
                fieldLabel: "Weight Calculation Type:",
                fieldType: "dropdown",
                options: [
                    {
                        label: "core,plus,tools",
                        value: "core,plus,tools"
                    },
                    {label: "core", "value": "core"}
                ],
                defaultValue: "core,plus,tools",
                tooltip: "Select a weight calculation type"
            }
        ]
    },
    TriggerWeightCalGlobalFileDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_weights_etl_global_file",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_weights_etl_global_file/graph`,
        inputFields: [
            {
                fieldId: "gcs_global_file_path",
                fieldLabel: "GCS Input file Name: ",
                fieldType: "string",
                tooltip: " GCS location including name of the input global file"
            },
            {
                fieldId: "gcs_bucket_name",
                fieldLabel: "GCS Bucket: ",
                fieldType: "string",
                defaultValue: getIngestionGCSPrefix(AppConfig.environment, "gcs_bucket"),
                tooltip: "Give the GCS bucket name where the spss files are stored"
            },
            {
                fieldId: "year",
                fieldLabel: "Year: ",
                fieldType: "dropdown",
                tooltip: "2 digit year for survey, e.g. 22, 23, 24"
            },

            {
                fieldId: "wave",
                fieldLabel: "Wave Label: ",
                fieldType: "dropdown",
                tooltip: "Wave number W1,W2,W3,OFFLINEW4 etc.."
            },
            {
                fieldId: "weight_calculation_types",
                fieldLabel: "Weight Calculation Type:",
                fieldType: "dropdown",
                options: [
                    {"label": "core,plus,tools", "value": "core,plus,tools"},
                    {"label": "core", "value": "core"}
                ],
                defaultValue: "core,plus,tools",
                tooltip: "Select a weight calculation type"
            }
        ]
    },

    TriggerWeightCalculationSnowflakeDAG: {
        triggerDagPathProperty: "TriggerDAG",
        dagIdProperty: "ao_weights_etl_snowflake_to_snowflake",
        triggerDAGLabel: "Trigger DAG",
        dagExecutionLog: `${AppConfig.airflowBaseUrl}/ao_weights_etl_snowflake_to_snowflake/graph`,
        inputFields: [
            {
                fieldId: 'snowflake_schema',
                fieldLabel: 'Snowflake Schema: ',
                fieldType: 'string',
                defaultValue: 'AO_SCHEMA',
                tooltip: 'Give the snowflake schema name'
            },
            {
                fieldId: "gcs_bucket_name",
                fieldLabel: "GCS Bucket: ",
                fieldType: "string",
                defaultValue: getIngestionGCSPrefix(AppConfig.environment, "gcs_bucket"),
                tooltip: "Give the GCS bucket name where the temp spss files are stored"
            },
            {
                fieldId: "gcs_snowflake_download_path",
                fieldLabel: "GCS Snowflake Download Path: ",
                fieldType: "string",
                defaultValue: "pipelines/audience_origin_weights_snowflake_to_snowflake/snowflake_data_export",
                tooltip: "Give the GCS bucket prefix name where the temp spss files are stored"
            },
            {
                fieldId: "gcs_weights_upload_path",
                fieldLabel: "GCS Weight Upload Path: ",
                fieldType: "string",
                defaultValue: "pipelines/audience_origin_weights_snowflake_to_snowflake/weights_export",
                tooltip: "Give the GCS bucket prefix name where the generated csv weight files per market will be stored"
            },
            {
                fieldId: "gcs_status_path",
                fieldLabel: "GCS Status Path: ",
                fieldType: "string",
                defaultValue: "pipelines/audience_origin_weights_snowflake_to_snowflake/pipeline_status",
                tooltip: "Give the GCS bucket prefix name where the generated status report per run be stored"
            },
            {
                fieldId: "gcs_path_report_files",
                fieldLabel: "GCS Report Path: ",
                fieldType: "string",
                defaultValue: "pipelines/audience_origin_weights_snowflake_to_snowflake/reports",
                tooltip: "Give the GCS bucket prefix name where the generated report per run be stored"
            },
            {
                fieldId: "year",
                fieldLabel: "Year: ",
                fieldType: "dropdown",
                tooltip: "2 digit year for survey, e.g. 22, 23, 24"
            },

            {
                fieldId: "wave",
                fieldLabel: "Wave Label: ",
                fieldType: "dropdown",
                tooltip: "Wave number W1,W2,W3,OFFLINEW4 etc.."
            },
            {
                fieldId: "markets",
                fieldLabel: "Markets: ",
                fieldType: "dropdown",
                tooltip: "CSV of market codes (use \"*\" to include all markets)"
            },
            {
                fieldId: "weight_calculation_types",
                fieldLabel: "Weight Calculation Type:",
                fieldType: "dropdown",
                options: [
                    {label: "core,plus,tools", value: "core,plus,tools"},
                    {label: "core", value: "core"}
                ],
                defaultValue: "core,plus,tools",
                tooltip: "Select a weight calculation type"
            },
            {
                fieldId: "use_ascription",
                fieldLabel: "Use  Ascription: ",
                fieldType: "boolean",
                defaultValue: true,
                tooltip: "Use ascription or not"
            }
        ]
    },
    weightConfigIngestionWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338315419787/AO+weight+metadata+pipeline",
    weightMarketFileCalculationWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338315715225/AO+weight+calculation+market+files+pipeline",
    weightSnowflakeCalculationWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338314600524/AO+weight+snowflake+calculation+pipeline",
    weightCalcMarketFileUploadPath:
        process.env.REACT_APP_WEIGHT_CALC_MARKET_FILE_UPLOAD_PATH ??
        getIngestionGCSPrefix(AppConfig.environment, "weight_market_file"),
    weightGlobalFileCalculationWikiLink:
        "https://wppchoreograph.atlassian.net/wiki/spaces/mP/pages/22338315815066/AO+weight+calculation+global+file+pipeline",
}
