import {getAllureDockerApiUrl} from "./utility/env";

export const getIngestionGCSPrefix = (env: string, pipeline: string) => {
    const nonProdEnv = new Set(["dev", "int"]);
    const pipelineNonProdPrefixMap = {
        "metadata": "/pipelines/audience_origin_meta/preprocessing",
        "raw": "/pipelines/audience_origin_fact_etl/pending",
        "ascription": "/pipelines/audience_origin_fact_etl_ascription/pending",
        "variable_order": "/pipelines/audience_origin_import_export_output_mapping/latest",
        "net_test_run_output": "/pipelines/audience_origin_net_test_run_etl/output",
        "net_test_run_input": "/pipelines/audience_origin_net_test_run_etl/configs",
        "net_test_run_validation": "/pipelines/audience_origin_net_test_run_etl/validation",
        "net_test_run_reports": "/pipelines/audience_origin_net_test_run_etl/reports",
        "net_metadata": "/ao-data-load",
        "net": "/pipelines/audience_origin_net_etl/pending",
        "net_config": "/audience-origin-portal/v1/workflow/compare/netYamlFiles",
        "weight_config": "/pipelines/ao_weights_metadata/configs/",
        "weight_global_file": "/pipelines/ao_weights_etl_global_file/global_files",
        "weight_snowflake_weights_upload": "/audience_origin_weights_snowflake_to_snowflake/weights_export",
        "weight_snowflake_temp_download": "/audience_origin_weights_snowflake_to_snowflake/snowflake_data_export",
        "weight_snowflake_status": "/audience_origin_weights_snowflake_to_snowflake/pipeline_status",
        "weight_snowflake_report": "/audience_origin_weights_snowflake_to_snowflake/reports",
        "weight_market_file": "/pipelines/audience_origin_weights_market_files/market_files",
        "gcs_bucket": "",
    };
    const pipelineProdPrefixMap = {
        "metadata": "/meta/preprocessing",
        "raw": "/fact_etl/pending",
        "ascription": "/fact_etl_ascription/pending",
        "variable_order": "/pipelines/audience_origin_import_export_output_mapping/latest",
        "net_test_run_output": "/audience_origin_net_test_run_etl/output",
        "net_test_run_input": "/audience_origin_net_test_run_etl/configs",
        "net_test_run_validation": "/audience_origin_net_test_run_etl/validation",
        "net_test_run_reports": "/audience_origin_net_test_run_etl/reports",
        "net_metadata": "/ao-data-load",
        "net": "/audience_origin_net_etl/pending",
        "net_config": "/audience-origin-portal/v1/workflow/compare/netYamlFiles",
        "net_test_run": "/audience_origin_net_test_run_etl/configs",
        "weight_config": "/ao_weights_metadata/configs/",
        "weight_global_file": "/ao_weights_etl_global_file/global_files",
        "weight_snowflake_weights_upload": "/weights_snowflake_to_snowflake/weights_export",
        "weight_snowflake_temp_download": "/weights_snowflake_to_snowflake/snowflake_data_export",
        "weight_snowflake_status": "/weights_snowflake_to_snowflake/pipeline_status",
        "weight_snowflake_report": "/weights_snowflake_to_snowflake/reports",
        "weight_market_file": "/weights_market_files/market_files",
        "gcs_bucket": "",
    };

    if (nonProdEnv.has(env)) {
        if (pipeline === "gcs_bucket") {
            return AppConfig.ingestionBucketUrl.replace("https://console.cloud.google.com/storage/browser/", "");
        }
        if (pipeline === "variable_order") {
            console.log(env +'-non prod variable order: ' + AppConfig.exportGcsBucket + pipelineNonProdPrefixMap[pipeline])
            return  AppConfig.exportGcsBucket+ pipelineProdPrefixMap[pipeline];
        }
        console.log(env + '-non prod: ' + AppConfig.ingestionBucketUrl + pipelineNonProdPrefixMap[pipeline])
        return AppConfig.ingestionBucketUrl + pipelineNonProdPrefixMap[pipeline];
    } else {
        if (pipeline === "gcs_bucket") {
            return AppConfig.ingestionBucketUrl.replace("https://console.cloud.google.com/storage/browser/", "");
        }
        if (pipeline === "variable_order") {
            console.log(env +'-prod variable order: ' + AppConfig.exportGcsBucket + pipelineProdPrefixMap[pipeline])
            return  AppConfig.exportGcsBucket+ pipelineProdPrefixMap[pipeline];
        }
        console.log(env + '-prod:' + AppConfig.ingestionBucketUrl + pipelineProdPrefixMap[pipeline])
        return AppConfig.ingestionBucketUrl + pipelineProdPrefixMap[pipeline];

    }
}

export const AppConfig = {
    metadataIngestionDownloadLink:
        process.env.REACT_APP_METADATA_INGESTION_DOWNLOAD_LINK ??
        "https://insidemedia.sharepoint.com/sites/GDT-GLOBAL-AudienceOriginTeam/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGDT%2DGLOBAL%2DAudienceOriginTeam%2FShared%20Documents%2F02%2E%20Core%20Survey%2F05%2E%20Data%2F01%2E%20Spec%2FSnowflake%20Database%2FAO%202%2Ex%20Snowflake%20Specs&p=true&ga=1",
    routerBaseName: process.env.REACT_APP_ALLURE_DOCKER_PUBLIC_API_URL_PREFIX,
    alluerDockerApiUrl: getAllureDockerApiUrl(),
    baseUrl: process.env.REACT_APP_ALLURE_DOCKER_PUBLIC_API_URL ?? 'http://localhost:5050',
    slackChannelName: process.env.REACT_APP_SLACK_CHANNEL ?? "ao-qa",
    emailDistList:
        process.env.REACT_APP_EMAIL_DIST_CHANNEL ?? "karen.su@choreograph.com",
    aoPortalApiUrl:
        process.env.REACT_APP_PORTAL_API_URL ??
        "http://localhost:8010/proxy/audience-origin-portal/v1",
    //"http://localhost:9191/audience-origin-portal/v1",
    aoQuestionnaireApiUrl:
        process.env.REACT_APP_QUESTIONNAIRE_API_URL ??
        "http://localhost:8010/proxy/audience-origin-questionnaire/v1",

    semanticSearchApiUrl: process.env.REACT_APP_SEMANTIC_API_URL ?? "http://localhost:8010/proxy/semantic-search/v1",

    ingestionBucketUrl: process.env.REACT_APP_INGESTION_GCS_BUCKET_URL ??
        "https://console.cloud.google.com/storage/browser/gcs-gcp-cgh-dc-ao-npd-amer-ao-nonprod",

    airflowBaseUrl: process.env.REACT_APP_AIRFLOW_UI_BASE_URL ?? "https://e01981beb6df46fc94b37fcee351b45b-dot-us-west1.composer.googleusercontent.com/dags",

    exportGcsBucket:
        process.env.REACT_APP_EXPORT_GCS_BUCKET_URL ??
        "https://console.cloud.google.com/storage/browser/gcs-gcp-cgh-dc-ao-npd-amer-ao-nonprod",
    environment:
        process.env.REACT_APP_ENV ?? "dev"
};

