import React, { useEffect } from "react";
import { AudienceOriginRequest, EndPoints } from "../../../api/audience-origin";
import { useQuery } from "@tanstack/react-query";
import { WppAccordion, WppSpinner, WppTypography } from "@platform-ui-kit/components-library-react";
import { IngestionGrid } from "../IngestionGrid/IngestionGrid";
import styles from "../MetadataIngestion/new-metadata-ingestion-workflow.module.scss";
import { Overlay } from "antd/es/popconfirm/PurePanel";
import { display, textAlign, width } from "@mui/system";

// Column definitions for Weighting
const columnStyle = {
    'white-space': 'normal',
    'overflow-wrap': 'break-word',
    'text-overflow': 'ellipsis',
    'overflow': 'hidden',
    'max-width': 'inherit',
    'max-height': '70px', // Adjust based on your header height
    'line-height': '40px',
    'text-align': 'center',
    "margin-right":'80px' // Adjust based on your row height
  }
const columnDefsWeighting = [
    { headerName: "Order", field: "Order", sortable: true ,cellstyle:columnStyle},
    { headerName: "Methodology", field: "Methodology", sortable: true ,cellstyle:columnStyle},
    { headerName: "Quota", field: "Quota", sortable: true,cellstyle:columnStyle },
    { headerName: "Sample Size", field: "Sample size", sortable: true ,cellstyle:columnStyle},
    { headerName: "Country", field: "Country", sortable: true,cellstyle: columnStyle},
    { headerName: "Target Completes", field: "Target completes", sortable: true,cellstyle:columnStyle,width: '500%'},
    { headerName: "Cell", field: "Cell", sortable: true },
];


const columnDefsWeightingModified = [
    { headerName: "country", field: "country", sortable: true ,cellstyle: columnStyle,width: '500%'},


 


       { headerName: "new_value", field: "new_value", sortable: true,cellstyle: columnStyle},
       { headerName: "field_name", field: "field_name", sortable: true ,cellstyle: columnStyle},

    { headerName: "old_value", field: "old_value", sortable:true,cellstyle: columnStyle},

];
// Column definitions for Markets
const columnDefsMarketAdded = [
    { headerName: "Methodology", field: "Methodology", sortable: true ,width:"200%",cellstyle:columnStyle,},
    { headerName: "Total Population", field: "Total Population", sortable: true ,cellStyle:columnStyle},
    { headerName: "Total Internet Population", field: "Total Internet Population", sortable: true ,cellStyle:columnStyle},
    { headerName: "Size of Audience for Total Population", field: "Size of audience for total population", sortable: true ,cellStyle:columnStyle},
    { headerName: "Size of Audience for Internet Population", field: "Size of audience for internet population", sortable: true ,cellStyle:columnStyle},
    { headerName: "Source", field: "Source", sortable: true,cellStyle:columnStyle },
    { headerName: "Sample Size per Wave", field: "Sample size per wave", sortable: true ,cellStyle:columnStyle},
    { headerName: "Total Sample in Annual Roll-Up", field: "Total sample in annual roll-up", sortable: true,cellStyle:columnStyle },
];

const columnDefsMarketDeletedModified = [
    { headerName: "Market", field: "Market", sortable: true },
    { headerName: "Methodology", field: "Methodology", sortable: true },
    { headerName: "Total Population", field: "Total Population", sortable: true },
    { headerName: "Total Internet Population", field: "Total Internet Population", sortable: true },
    { headerName: "Size of Audience for Total Population", field: "Size of audience for total population", sortable: true },
    { headerName: "Size of Audience for Internet Population", field: "Size of audience for internet population", sortable: true },
    { headerName: "Source", field: "Source", sortable: true },
    { headerName: "Sample Size per Wave", field: "Sample size per wave", sortable: true },
    { headerName: "Total Sample in Annual Roll-Up", field: "Total sample in annual roll-up", sortable: true },
];

const columnDefsMarketModified = [
    { headerName: "Market", field: "Market", sortable: true , cellStyle:columnStyle},
        { headerName: "Field Name", field: "Field Name", sortable: true , cellStyle:columnStyle},
    { headerName: "Old Value", field: "Old Value", sortable: true , cellStyle: columnStyle},
    { headerName: "New Value", field: "New Value", sortable: true , cellStyle: columnStyle},
];
export let data2api = null;

const useCombinedMetadataDiff = ( gcsFileLocation2, survey) => {
    return useQuery(
        ["combinedMetadataDiff" , gcsFileLocation2, survey],
        async () => {
            const [response1, ] = await Promise.all([
                AudienceOriginRequest.get(`${EndPoints.NetMetadataDiff}?fileName=${gcsFileLocation2}&survey=${survey}`).catch(error => {
                    throw {firstError:error};
                }),
                
               // AudienceOriginRequest.get(`${EndPoints.NetyamldataDiff}?fileName=${gcsFileLocation1}&survey=${survey}`)
            ]);
            //data2api=response2.data
            return { data1: response1.data,  };
        },
        {
            retry: false,
        }
    );
};


// export const data2api = (response2.data) => response2.data;
// console.log(data2api);

const getFileName = (file) => {
    const fileStringSplit = file.split("/");
    return fileStringSplit.length > 0 ? fileStringSplit[fileStringSplit.length - 1] : "";
};

const normalizeData = (data) => {
    if (!data) return [];
    return Array.isArray(data) ? data : [data];
};

const extractWeightingData = (sheet) => {
    if (!sheet.added) return [];
    const addedData = normalizeData(sheet.added);
    
    return addedData.flatMap(item => {
        const records = Object.entries(item).map(([key, record]) => ({
            Order: record["Order"],
            Methodology: record["Methodology"],
            Quota: record["Quota"],
            "Sample size": record["Sample size"],
            Country: record["Country"],
            "Target completes": record["Target completes"],
            Cell: record["Cell"],
        }));
        return records;
    });
};

const extractMarketData = (sheet) => {
    if (!sheet.added) return [];
    const addedData = normalizeData(sheet.added);
    
    return addedData.flatMap(item => {
        const records = Object.entries(item).map(([key, record]) => ({
            Market: key,
            Methodology: record["Methodology"],
            "Total Population": record["Audience definition for total population"],
            "Total Internet Population": record["Audience definition for internet population"],
            "Size of audience for total population": record["Size of audience for total population"],
            "Size of audience for internet population": record["Size of audience for internet population"],
            Source: record["Source for projection figures"],
            "Sample size per wave": record["Sample size per wave"],
            "Total sample in annual roll-up": record["Total sample in annual roll-up"],
        }));
        return records;
    });
};

const extractWeightingDeletedData = (sheet) => {
    if (!sheet.deleted) return [];
    const deletedData = normalizeData(sheet.deleted);
    
    return deletedData.flatMap(item => {
        const records = Object.entries(item).map(([key, record]) => ({
            Order: record["Order"],
            Methodology: record["Methodology"],
            Quota: record["Quota"],
            "Sample size": record["Sample size"],
            Country: record["Country"],
            "Target completes": record["Target completes"],
            Cell: record["Cell"],
        }));
        return records;
    });
};

const extractMarketDeletedData = (sheet) => {
    if (!sheet.deleted) return [];
    const deletedData = normalizeData(sheet.deleted);
    
    return deletedData.flatMap(item => {
        const records = Object.entries(item).map(([key, record]) => ({
            Market: key,
            Methodology: record["Methodology"],
            "Total Population": record["Audience definition for total population"],
            "Total Internet Population": record["Audience definition for internet population"],
            "Size of audience for total population": record["Size of audience for total population"],
            "Size of audience for internet population": record["Size of audience for internet population"],
            Source: record["Source for projection figures"],
            "Sample size per wave": record["Sample size per wave"],
            "Total sample in annual roll-up": record["Total sample in annual roll-up"],
        }));
        return records;
    });
};

const extractWeightingModifiedData = (sheet) => {
    if (!sheet.modified) return [];
    const modifiedData = normalizeData(sheet.modified);
    
    return modifiedData.flatMap(item => {
        const records = Object.entries(item).map(([key, record]) => (

            console.log(record),
            {
           
            Market: key,
                    "country":key,
                    "field_name": record[0]["field_name"] ,
                    "old_value": record[0]["old_value"],
                    "new_value": record[0]["new_value"],
                }
                
            )
            
        );
             //   console.log(records);
                return records;
               
            });
        };

const extractMarketModifiedData = (sheet) => {
    if (!sheet.modified) return [];
    const modifiedData = normalizeData(sheet.modified);
    
    return modifiedData.flatMap(item => {
        return Object.entries(item).flatMap(([market, modifications]) => {
            if (Array.isArray(modifications)) {
                return modifications.map(modification => ({
                    Market: market,
                    "Field Name": modification.field_name || "N/A",
                    "Old Value": modification.old_value,
                    "New Value": modification.new_value,
                }));
            }
            return [];
        });
    });
};

export const CombinedWeightDiffStep = ({ wave,  gcsFileLocation2, onSuccess }) => {
    const { status, data, error, isFetching } = useCombinedMetadataDiff( gcsFileLocation2, wave);

    useEffect(() => {
        if (onSuccess) {
            onSuccess(!isFetching && !error);
        }
    }, [error, isFetching, onSuccess]);

    const getErrorMessage = (error) => {
        return error?.firstError?.response?.data?.message ;
    };

    return (
        <div style={{ overflow: "scroll", height: "100%", width: "100%" }}>
            {isFetching && <WppSpinner size="m" />}
            {error && (
                <WppTypography>
                    <strong>Warning: </strong>
                    {getErrorMessage(error)}
                </WppTypography>
            )}
            {data && !error && (
                <div>
                    <div style={{ margin: "2rem" }}>
                        {data.data1.files.map((file, index) => (
                            <div key={index} style={{ marginBottom: "0.5rem" }}>
                                <WppTypography type="l-midi">
                                    {index === 0 ? 'Old File (Processed):' : 'New File (Pre-Processed):'}
                                </WppTypography>
                                <WppTypography type="l-body" className={styles.highlightText}>
                                    {getFileName(file)}
                                </WppTypography>
                            </div>
                        ))}
                    </div>
                    <div style={{ marginTop: "1rem", marginLeft: "2rem", marginRight: "3rem" }}>
                        {data.data1.sheet.map((sheet, index) => {
                            let addedData = [];
                            let deletedData = [];
                            let modifiedData = [];

                            if (sheet.name === "Weighting") {
                                addedData = extractWeightingData(sheet);
                                deletedData = extractWeightingDeletedData(sheet);
                                modifiedData = extractWeightingModifiedData(sheet);
                            } else if (sheet.name === "Markets") {
                                addedData = extractMarketData(sheet);
                                deletedData = extractMarketDeletedData(sheet);
                                modifiedData = extractMarketModifiedData(sheet);
                            }

                            return (
                                <div key={index} style={{ marginBottom: '20px'}}>
                                    <h4>{sheet.name}</h4>
                                    <WppAccordion size="l">
                                        <WppTypography type="m-strong" slot="header">
                                            Added ({addedData.length})
                                        </WppTypography>
                                        {addedData.length > 0 && (
                                            <IngestionGrid 
                                                columns={sheet.name === "Weighting" ? columnDefsWeighting : columnDefsMarketAdded} 
                                                data={addedData} 
                                                status={status} 
                                            />
                                        )}
                                    </WppAccordion>
                                    <WppAccordion>
                                        <WppTypography type="m-strong" slot="header">
                                            Deleted ({deletedData.length})
                                        </WppTypography>
                                        {deletedData.length > 0 && (
                                            <IngestionGrid 
                                                columns={sheet.name === "Weighting" ? columnDefsWeighting : columnDefsMarketDeletedModified} 
                                                data={deletedData} 
                                                status={status} 
                                            />
                                        )}
                                    </WppAccordion>
                                    <WppAccordion  >
                                        <WppTypography type="m-strong" slot="header">
                                            Modified ({modifiedData.length})
                                        </WppTypography>
                                        {modifiedData.length > 0 && (
                                            <IngestionGrid 
                                                // style={{right:"200px"}}
                                                columns={sheet.name === "Markets" ? columnDefsMarketModified : columnDefsWeightingModified} 
                                                data={modifiedData} 
                                                status={status} 
                                            />
                                        )}
                                    </WppAccordion>
                                </div>
                            );
                        })}
                    </div>
{/* 
                    <div style={{ marginTop: "1rem", marginLeft: "2rem", marginRight: "3rem" }}>
                        {data.data1.sheet.map((sheet, index) => (
                            <AdditionalComponent key={index} sheet={sheet} status={status} />
                        ))}
                    </div> */}
                </div>
            )}
        </div>
    );
};
